<template>
    <div class="home" style="padding: 5px 27px;">
      <b-modal id="modal-xl" size="xl"
      ok-only
      :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
        ok-variant='neon'
        button-size="sm"
        ok-title="Close"
      >
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
      <img src="../assets/lg-dark.png" width="204"  class="align-top" alt="" style="width:156px;">
        
        <b-button size="sm" variant="outline-danger" @click="close()">
          &#10006;
        </b-button>
      </template>
      <b-modal id="modal-multi-3" size="lg" title="How to Get a Transaction HASH from MetaMask" ok-only>
  <p>
  <b>Step 1: Install and Set Up MetaMask</b>
  If you haven't already, install the MetaMask extension in your browser and set up your wallet. Visit the MetaMask website to download and install the extension.
  
  
  <br><b>Step 2: Initiate a Transaction</b>
  
  Open MetaMask by clicking on the extension icon in your browser toolbar.
  Log in to your MetaMask wallet, if required.
  On the main MetaMask screen, click on the "Send" button to initiate a transaction.
  
  <br><b>Step 3: Enter Transaction Details</b>
  
  In the "Recipient Address" field, enter the recipient's Ethereum address.
  Specify the amount you wish to send in the "Amount" field.
  Select the desired transaction fee under the "Gas Fee" section. You can adjust it according to your preference.
  
  <br><b>Step 4: Confirm and Send</b>
  
  Review the transaction details to ensure they are correct.
  Click on the "Next" button to proceed.
  MetaMask will display a summary of the transaction. Verify the details once again.
  To send the transaction, click on the "Confirm" button.
  <!-- </p> -->
  <!-- <img src="../assets/" alt="" class="d-block"> -->
  <!-- <p> -->
  <br><b>Step 5. Retrieve the transaction HASH</b>
  After completing your transaction, go into your Metamask (or trusted wallet) click on the transaction. 
  A box will appear where you can see your confirmed transactions. Click the upwards pointing arrow in a circle on the left. Then to the right of where it says ‘Status Confirmed’ in green it says ‘Copy transaction ID’ Copy this then paste this transaction ID into the required field on your purchase screen.  Now press “submit”
  <br><b>Note: The interface and options in MetaMask may vary slightly depending on the version and browser you are using.</b>
  </p>
    </b-modal>
    <div class="row justify-content-between">
      <!-- v-if="network == 'https://goldxscan.com/tx/'" -->
        <div class="col-12 col-md-6">
          <div class="d-md-none hsm">
            <h4>To Buy:</h4>
            <h5>1: Send Requested Amount to <b>Presale Address </b> </h5>
            <h5>2: Add <b>Transaction Hash</b> after it has completed along with email   </h5>
            <h5>3: Submit </h5>
          </div>
          <div class="d-none d-md-block hs">
            <h4>To Buy:</h4>
            <h5>1: Send Requested Amount to <b>Presale Address </b> </h5>
            <h5>2: Add <b>Transaction Hash</b> after it has completed along with email   </h5>
            <h5>3: Submit </h5>
          </div>
        </div>
        <div class="col-12 col-md-5 m-0 pr-0 pl-2">
          <pre-sale />
        </div>
      </div>
    
      <label for="" class="ft5"> Select Class </label>
    <select name="" id="" class="form-control ft5" v-model="slClass" :disabled='pType == "Fiat"'>
      <option value="Refiners">Refiners (${{refPrice }}) </option>
      <option value="Miners">Miners (${{ minPrice }})</option>
      <option value="Prospectors">Prospectors (${{ prosPrice }})</option>
      <option value="Custom">Custom </option>
    </select>
  <label for="" class="ft5">Select Quantity <span v-if="slClass == 'Custom'"> (1 = $12.5)</span> </label>
  
    <input type="number" name="" id="" min="1" :max="(slClass == 'Refiners') ? 4 : 1000"  class="form-control" v-model.number="slQty" :disabled='pType == "Fiat"'>
    <p class="text-danger mb-0" v-if="slClass == 'Prospectors' && slQty < 5">Minimum Purchase : 5 Prospectors</p>
    <p class="text-danger mb-0" v-if="slClass == 'Custom' && slQty < 5">Minimum Purchase : 5 Units</p>
    <div class="row">
      <div class="col" v-if="slClass !== 'Custom' && slQty > 1">
    <b-form-checkbox
      id="checkbox-1"
      v-model="needSingle"
      size="lg"
      name="checkbox-1"
      value="I Need Single NFT"
      unchecked-value="I Need Individual NFTs"
    >
    <span style="text-align:left;" > Want Single NFT</span>
      
    </b-form-checkbox>
    </div>
    <div class="col">
    <b-form-checkbox
    size="lg"
      id="checkbox-5"
      v-model="sacrifice"
      name="checkbox-5"
      value="I Want to Sacrifice My NFT"
      unchecked-value="I Want to Recieve My NFT"
    >
    <span class="glowing-text" style="text-align:left;font-size:66%;">Sacrifice Mining Power to Loanifi Phase 2</span>
      
    </b-form-checkbox>
    <div v-if="sacrifice == 'I Want to Sacrifice My NFT'">
      <span> <b>Estimated Phase 2 Points: {{tPoints}}</b> <i class="glowing-text" v-if="bonus.bonus > 1">{{bonus.bonus}}X Bonus</i> </span>
    </div>
  </div>
    </div>
  <div class="row">
    <div class="col-12 d-none">
      <b-form-group label="Payment Type" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        id="radio-group-1"
        v-model="pType"
        :options="['Crypto','Fiat']"
        :aria-describedby="ariaDescribedby"
        name="radio-options"
      ></b-form-radio-group>
    </b-form-group>
    </div>
    <div class="col-12" v-if="pType == 'Fiat'">
      <div v-if="elementsOptions.clientSecret !== null">
        <stripe-element-payment
        ref="paymentRef"
        :pk="pk"
        :elements-options="elementsOptions"
        :confirm-params="confirmParams"
      />
      </div>
      <div v-else>
Please Wait..
      </div>
      
    </div>
    <div class="col-12" v-if="pType == 'Crypto'">
      <label for="" style="font-size: 83%;margin-bottom: 1px;">Select Network
        & Transaction Hash (Required) 
      <b-button v-b-modal.modal-multi-3 size="sm" style="    font-size: 9px;
      padding: 2px 8px;" >How to Get a Transaction Hash from MetaMask</b-button>
        </label>
      <b-input-group>
      <!-- <template #append> 
        <input type="text" name="" id="" class="form-control">
      </template> -->
      <template #prepend>
        <select name="" id="" class="form-control" v-model="network">
        <option value="https://goldxscan.com/tx/"> GOLDXscan </option>
        <!-- <option v-if="sacrifice == 'I Want to Sacrifice My NFT'" value="https://goldxscan.com/tx/"> GOLDXscan </option> -->
        <option value="https://bscscan.com/tx/"> BSCscan </option>
        <option value="https://etherscan.io/tx/"> Etherscan </option>
      </select>
      </template>
  
      <b-form-input  type="text" name="" id="" v-model="txHash"></b-form-input>
    </b-input-group>
    </div>
    
    <!-- <div class="col-md-12">
        <input type="checkbox" v-model="isSelfMint" :value="1"  name="" id=""><label for="" class="pl-2">I want to mint NFT myself</label>
      </div> -->
    <div class="col-md-6 mb-2" v-if="pType == 'Crypto'">
      <label for="" class="ft5"> Select Cryptocurrency </label>
    <b-dropdown id="dropdown-1" :text="'Selected Cryptocurrency: '+slCurr" v-model="slCurr" class="d-block w-100 ft5" >
      <b-dropdown-item :active="slCurr == 'WGOLDX'" @click="slCurr = 'GOLDX'" v-if="network == 'https://goldxscan.com/tx/' && currentPrice.GOLDX > 0"> <span class="d-inline-block" style="width:55px;font-size:13px;">GOLDX</span> <img src="../assets/goldx.png" alt="" style="max-width:20px;"> </b-dropdown-item>
      <b-dropdown-item :active="slCurr == 'WGOLDX'" @click="slCurr = 'WGOLDX'" v-if="network !== 'https://etherscan.io/tx/' && currentPrice.WGOLDX > 0"> <span class="d-inline-block" style="width:55px;font-size:13px;">WGOLDX</span> <img src="../assets/goldx.png" alt="" style="max-width:20px;"> </b-dropdown-item>
      <b-dropdown-item :active="slCurr == 'HBSC'" @click="slCurr = 'HBSC'" v-if="network == 'https://bscscan.com/tx/' && currentPrice.HBSC > 0" > <span class="d-inline-block" style="width:55px;">HBSC</span> <img src="../assets/hbsc.png" alt="" style="max-width:20px;"> </b-dropdown-item>
      <b-dropdown-item :active="slCurr == 'USDT'" @click="slCurr = 'USDT'" v-if="network !== 'https://goldxscan.com/tx/'"> <span class="d-inline-block" style="width:55px;">USDT</span> <img src="../assets/usdt.png" alt="" style="max-width:20px;"> </b-dropdown-item>
      <b-dropdown-item :active="slCurr == 'USDC'" @click="slCurr = 'USDC'" v-if="network !== 'https://goldxscan.com/tx/'"> <span class="d-inline-block" style="width:55px;">USDC</span> <img src="../assets/usdc.png" alt="" style="max-width:20px;"> </b-dropdown-item>
      <b-dropdown-item :active="slCurr == 'USDX'" @click="slCurr = 'USDX'" v-if="network !== 'https://etherscan.io/tx/'"> <span class="d-inline-block" style="width:55px;">USDX</span> <img src="../assets/goldx.png" alt="" style="max-width:20px;"> </b-dropdown-item>
      <b-dropdown-item :active="slCurr == 'ETH'" @click="slCurr = 'ETH'" v-if="network == 'https://etherscan.io/tx/'"><span class="d-inline-block" style="width:55px;"> ETH</span> <img src="../assets/eth.png" alt="" style="max-width:20px;"> </b-dropdown-item>
      <b-dropdown-item :active="slCurr == 'XAUT'" @click="slCurr = 'XAUT'" v-if="network == 'https://etherscan.io/tx/'"><span class="d-inline-block" style="width:55px;"> XAUT</span> <img src="../assets/eth.png" alt="" style="max-width:20px;"> </b-dropdown-item>
      <b-dropdown-item :active="slCurr == 'PAXG'" @click="slCurr = 'PAXG'" v-if="network == 'https://etherscan.io/tx/'"><span class="d-inline-block" style="width:55px;"> PAXG</span> <img src="../assets/eth.png" alt="" style="max-width:20px;"> </b-dropdown-item>
      <b-dropdown-item :active="slCurr == 'WBTC'" @click="slCurr = 'WBTC'" v-if="network == 'https://etherscan.io/tx/'"><span class="d-inline-block" style="width:55px;"> WBTC</span> <img src="../assets/eth.png" alt="" style="max-width:20px;"> </b-dropdown-item>
      <b-dropdown-item :active="slCurr == 'BNB'" @click="slCurr = 'BNB'" v-if="network == 'https://bscscan.com/tx/'"><span class="d-inline-block" style="width:55px;"> BNB</span> <img src="../assets/bnb.png" alt="" style="max-width:20px;"> </b-dropdown-item>
      <b-dropdown-item :active="slCurr == 'WBNB'" @click="slCurr = 'WBNB'" v-if="network == 'https://bscscan.com/tx/'"><span class="d-inline-block" style="width:55px;"> WBNB</span> <img src="../assets/bnb.png" alt="" style="max-width:20px;"> </b-dropdown-item>
      <b-dropdown-item :active="slCurr == 'DAI'" @click="slCurr = 'DAI'" v-if="network !== 'https://goldxscan.com/tx/'"><span class="d-inline-block" style="width:55px;"> DAI</span> <img src="../assets/dai.png" alt="" style="max-width:20px;"> </b-dropdown-item>
    </b-dropdown>
    </div>
    <div class="col-md-6 mb-2">
      <label for="" class="ft5">Your Email Address (Required)</label>
      <input type="email" name="" id="" v-model="emailBuyer" class="form-control" :class=[VerEmailBuyer]>
    </div>
    <div class="col-md-12 mb-2" v-if="pType == 'Fiat'">
    <button class="btn neon-button form-control" style="line-height:12px;" @click="pay" :disabled="VerEmailBuyer !== 'border border-success'"> Pay </button>
      
    </div>
    <!-- <div class="col-md-6 mb-2">
      
      
    </div>
     -->
      <div class="col-12">
        <p style="max-width: 350px;" class="mx-auto b-text shadow rounded bg-white text-center text-dark mb-2 mt-3" v-if="slClass == 'Refiners'">
          <span v-if='pType == "Crypto"'>
            <span v-if="diss !== 1">
              Total Payable:
              <del class="text-danger">
                 {{ ((refPrice / currentPrice[slCurr]) * Number(slQty)).toFixed( (slCurr == 'USDT' || slCurr == 'USDC' || slCurr == 'DAI' ) ? 2 : 4  ) }} 
              </del>
              <span class="text-success">
                {{ (((refPrice / currentPrice[slCurr]) * Number(slQty)) * diss ).toFixed( (slCurr == 'USDT' || slCurr == 'USDC' || slCurr == 'DAI' ) ? 2 : 4  ) }} 
              </span>
              {{ slCurr }}
            </span>
            <span v-else>
            Total Payable:  {{ ((refPrice / currentPrice[slCurr]) * Number(slQty)).toFixed( (slCurr == 'USDT' || slCurr == 'USDC' || slCurr == 'DAI' ) ? 2 : 4  ) }} {{ slCurr }}
            </span>
          </span>
          <br>
          Total USD Value: $
          <span v-if="diss !== 1">{{ (refPrice * Number(slQty)) * diss }}</span>
          <span v-else>{{ (refPrice * Number(slQty)) }}</span>
       
        </p>
  
    <p style="max-width: 350px;" class="mx-auto b-text shadow rounded bg-white text-center text-dark mb-2 mt-3" v-if="slClass == 'Miners'">
      <span v-if='pType == "Crypto"'>
        <span v-if="diss !== 1">
              Total Payable:
              <del class="text-danger">
                 {{ ((minPrice / currentPrice[slCurr]) * Number(slQty)).toFixed( (slCurr == 'USDT' || slCurr == 'USDC' || slCurr == 'DAI' ) ? 2 : 4  ) }} 
              </del>
              <span class="text-success pl-2">
                {{ (((minPrice / currentPrice[slCurr]) * Number(slQty)) * diss  ).toFixed( (slCurr == 'USDT' || slCurr == 'USDC' || slCurr == 'DAI' ) ? 2 : 4  ) }} 
              </span>
              {{ slCurr }}
        </span>
        <span v-else>
            Total Payable:  {{ ((minPrice / currentPrice[slCurr]) * Number(slQty)).toFixed( (slCurr == 'USDT' || slCurr == 'USDC' || slCurr == 'DAI' ) ? 2 : 4  ) }} {{ slCurr }}
        </span>
        <!-- Total Payable:  {{ ((minPrice / currentPrice[slCurr] ) * Number(slQty)).toFixed( (slCurr == 'USDT' || slCurr == 'USDC' || slCurr == 'DAI' ) ? 2 : 4 ) }} {{ slCurr }} -->
      </span>
      <br>
      <!-- Total USD Value: $ {{ (minPrice * Number(slQty)) }} -->
      Total USD Value: $
          <span v-if="diss !== 1">{{ (minPrice * Number(slQty)) * diss }}</span>
          <span v-else>{{ (minPrice * Number(slQty)) }}</span>
    </p>
  
    <p style="max-width: 350px;" class="mx-auto b-text shadow rounded bg-white text-center text-dark mb-2 mt-3" v-if="slClass == 'Prospectors'">
      <span v-if='pType == "Crypto"'>
        <span v-if="diss !== 1">
          Total Payable:
          <del class="text-danger">
            {{ (((prosPrice / currentPrice[slCurr] ) * Number(slQty)) ).toFixed( (slCurr == 'USDT' || slCurr == 'USDC' || slCurr == 'DAI' ) ? 2 : 4 ) }} {{ slCurr }} 
          </del>
          <span class="text-success pl-2">
            {{ (((prosPrice / currentPrice[slCurr] ) * Number(slQty)) * diss ).toFixed( (slCurr == 'USDT' || slCurr == 'USDC' || slCurr == 'DAI' ) ? 2 : 4 ) }} {{ slCurr }}
          </span>
        </span>
        <span v-else>
          Total Payable:  {{ ((prosPrice / currentPrice[slCurr] ) * Number(slQty)).toFixed( (slCurr == 'USDT' || slCurr == 'USDC' || slCurr == 'DAI' ) ? 2 : 4 ) }} {{ slCurr }}
        </span>
      </span>  

      <br>
      Total USD Value: $ <span v-if="diss !== 1">{{ (prosPrice * Number(slQty)) * diss  }}</span>
          <span v-else>{{ (prosPrice * Number(slQty)) }}</span>
    </p>
    <p style="max-width: 350px;" class="mx-auto b-text shadow rounded bg-white text-center text-dark mb-2 mt-3" v-if="slClass == 'Custom'">
      <span v-if='pType == "Crypto"'>Total Payable:  {{ ((prosPrice / currentPrice[slCurr] ) * Number(slQty)).toFixed( (slCurr == 'USDT' || slCurr == 'USDC' || slCurr == 'DAI' ) ? 2 : 4 ) }} {{ slCurr }}</span>
      <br>
      Total USD Value: $
      <span v-if="diss !== 1">{{ (prosPrice * Number(slQty)) * diss }}</span>
          <span v-else>{{ (prosPrice * Number(slQty)) }}</span>
    </p>
  <!-- <p class="" v-if="pType == 'Crypto'"> <b> Please send the final amount shown below to the presale wallet address shown above.  Once transaction confirmed please enter the Transaction Hash into the Transaction Hash (Required) field and press the Submit button. </b> </p> -->
  
  <div v-if="pType == 'Crypto'">
    <div v-if="started">
      <p v-if="started" class="text-center mb-0"> <b>Please wait and approve all transactions, don't close the window</b> </p>
    <div v-if="started" style="    position: relative;
    left: 50%;">
      <b-spinner label="Spinning" style="width: 3rem; height: 3rem;background: #b99653;
    position: absolute;"> </b-spinner>
  <b-spinner type="grow" label="Spinning" style="width: 3rem; height: 3rem;"> </b-spinner>
    </div>
    </div>
    <div v-else>
      <div v-if="$store.state.userWallet">
        <button class="btn neon-button form-control mb-1" style="line-height:12px;" @click="submit" :disabled="slQty < 1 || txHash.length < 2 || emailBuyer.length < 2"> Submit </button>
      <!-- <button class="btn neon-button form-control mb-1" style="line-height:12px;" @click="beforeSubmit()" :disabled="slQty < 1 || emailBuyer.length < 2"> Buy </button> -->
    </div>

    <div v-else>
       <button class="btn neon-button form-control mb-1" @click="connectingWallet()" >Connect Wallet </button>
    </div>

    </div>
    <!-- <div v-else>
    <button class="btn neon-button form-control mb-1" style="line-height:12px;" @click="submit" :disabled="slQty < 1 || txHash.length < 2 || emailBuyer.length < 2"> Submit </button>
    </div> -->
    <!-- <button class="btn neon-button form-control mb-1" style="line-height:12px;" @click="pType = 'Fiat'" :disabled="slQty < 1"> Pay VIA Card </button> -->
  
  </div>
  
      </div>
  </div>
  
    </b-modal>
    </div>
  </template>
  
  <script>
import Web3 from 'web3';
  import axios from 'axios'
   import PreSale from "../components/PreSale.vue";
const CoinGecko = require('coingecko-api');
const CoinGeckoClient = new CoinGecko();
import { StripeElementPayment } from '@vue-stripe/vue-stripe';
  export default {
    props:["power"],
    components:{PreSale,StripeElementPayment, },
    async mounted(){
      this.getCg()
      this.getDex()
      if(this.$route.params.wallet){
        // console.log("we found a params",this.$route.params.wallet)
      await this.validateReferral(this.$route.params.wallet);
      // document.getElementById("buynow").focus();
      setTimeout(() => {
        document.getElementById("buynow").scrollIntoView();
      }, 2000);
      }else{
        this.validateReferral('No Wallet')
      }
    //   await this.fetchNFTs()
    //   await this.fetchUsers()
    //   await this.$store.dispatch("pullUsers")
    
    },
    methods:{
      async connectingWallet(){
      if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        let accounts =  await web3.eth.getAccounts()
        if (accounts) {
        // this.userWallet = accounts[0];
        // this.$store.dispatch("getInvites", this.userWallet)
        this.$store.state.userWallet = accounts[0];
      }

        
      }
      },
      async apiCallToGeneratePaymentIntent(amount){
        let dec = await this.$store.dispatch("generatePaymentIntent",  {amount})
        console.log("in BSC", dec)
        return dec.data.paymentIntent;
      },
      async generatePaymentIntent (amount) {
      const paymentIntent = await this.apiCallToGeneratePaymentIntent(amount); // this is just a dummy, create your own API call
      this.elementsOptions.clientSecret = paymentIntent.client_secret;
      this.pid = paymentIntent.id;
      this.confirmParams.return_url = this.$store.state.URL+'/api/success-payment/'+paymentIntent.id
    },
    pay () {
      
      let priceUsd = ''
        let priceCrypto = ''
  
        if(this.slClass == 'Refiners'){
         priceCrypto = (this.refPrice / this.currentPrice[this.slCurr]) * this.slQty
         priceUsd = this.refPrice * this.slQty
        }else if(this.slClass == 'Miners'){
         priceUsd = this.minPrice * this.slQty
          priceCrypto = (this.minPrice / this.currentPrice[this.slCurr]) * this.slQty
        }else if(this.slClass == "Custom"){
          priceUsd = this.slQty * 5
          priceCrypto = ( 5 / this.currentPrice[this.slCurr]) * this.slQty 
        }
        else{
         priceUsd = this.prosPrice * this.slQty
          priceCrypto = (this.prosPrice / this.currentPrice[this.slCurr]) * this.slQty
        }
        
        let tx = {
          txHash: "https://dashboard.stripe.com/test/payments/"+this.pid,
          type: this.slClass,
          qty: this.slQty,
          priceUSD:priceUsd,
          isSelfmint:this.isSelfMint,
          priceCrypto:priceCrypto,
          crypto:'$',
          pid:this.pid,
          isCrypto: false,
          email: this.emailBuyer,
          refWallet:this.refWallet,
          refEmail:this.refEmail,
          refUsername:this.refUsername,
          userWallet:"",
        }
        this.$store.dispatch("createTempPayment",{item: tx})
      //verify email
      this.$refs.paymentRef.submit();
    },
      copyName(link){
        navigator.clipboard.writeText(link)
        this.$root.Toast.fire({icon: "success",title:"Wallet Copied"})
      },
      copy(link)
    {
      navigator.clipboard.writeText(link)
        this.$root.Toast.fire({icon: "success",title:"Presale Address Copied"})
    },
      makeSearch(){
        if(this.search.length){
          this.isSearch = true
        }
        
      },
      fetchUsers(){
        this.$store.dispatch("getUsers")
      },
      getFunction(network, token){
      if(network == "https://goldxscan.com/tx/"){
        if(token == 'GOLDX') return "makeBuy"
        else return "makeBuyToken"
      }
      if(network == "https://etherscan.io/tx/"){
        if(token == 'ETH') return "makeBuyEther"
        else return "makeBuyTokenEther"
      }
      if(network == "https://bscscan.com/tx/"){
        if(token == 'BNB') return "makeBuyBsc"
        else return "makeBuyTokenBsc"
      }  
      },
      getRequiredChain(network){
        if(network == "https://goldxscan.com/tx/"){
        return {name:"GOLDX MAINNET", id: this.GOLDX_CHAIN_ID}
      }
      if(network == "https://etherscan.io/tx/"){
        return {name:"ETHERIUM MAINNET",id:this.ETH_CHAIN_ID}
      }
      if(network == "https://bscscan.com/tx/"){
        return {name:"BINANCE SMART CHAIN", id: this.BSC_CHAIN_ID}
      }  
      },
      async beforeSubmit(){
        //check the network
        let RC = this.getRequiredChain(this.network)
        const chainId = await this.$store.state.provider.request({
            method: 'eth_chainId'
          })
          console.log(RC, '==' ,chainId)
          if(RC.id == chainId){
            this.submit()
          }else{
            let message = "Please swithc to "+RC.name
            this.$root.Toast.fire({icon: "warning",title:message})

          }
      },
      async submit(){
        this.started = true
        let priceUsd = ''
        let priceCrypto = ''
  
        if(this.slClass == 'Refiners'){
         priceCrypto = ((this.refPrice / this.currentPrice[this.slCurr]) * this.slQty) * this.diss
         priceUsd = (this.refPrice * this.slQty) * this.diss
        }else if(this.slClass == 'Miners'){
         priceUsd = (this.minPrice * this.slQty) * this.diss
          priceCrypto = ((this.minPrice / this.currentPrice[this.slCurr]) * this.slQty) * this.diss
        }else if(this.slClass == "Custom"){
          priceUsd = (this.slQty * this.prosPrice) * this.diss
          priceCrypto = (( this.prosPrice / this.currentPrice[this.slCurr]) * this.slQty) * this.diss
        }
        else{
         priceUsd = (this.prosPrice * this.slQty) * this.diss
          priceCrypto = ((this.prosPrice / this.currentPrice[this.slCurr]) * this.slQty) * this.diss
        }
        console.log({amount:priceCrypto, ref: this.refWallet, user: this.$store.state.userWallet })
        if(priceUsd > 2){
          // let func = this.getFunction(this.network, this.slCurr)
          let tx = {
          txHash: this.network+this.txHash,
          txHashComplete: this.network+this.txHash,
          type: this.slClass,
          qty: this.slQty,
          needSingle: this.needSingle,
          sacrifice: this.sacrifice,
          points: this.tPoints,
          priceUSD:priceUsd,
          isSelfmint:this.isSelfMint,
          priceCrypto:priceCrypto,
          crypto:this.slCurr,
          email: this.emailBuyer,
          refWallet:this.refWallet,
          refEmail:this.refEmail,
          refUsername:this.refUsername,
          userWallet:this.$store.state.userWallet,
        }
          // let action = await this.$store.dispatch(func,{tx, nt: this.network,amount:priceCrypto.toString() ,token: this.slCurr, ref: this.refWallet, user: this.$store.state.userWallet })
          // console.log(action)
          let action = await this.$store.dispatch("addPurchase", {tx})
          if(action.status){
        this.started = false
        if(this.started == false){
           this.qty = '';
           this.txHash = '';
           this.$bvModal.hide('modal-xl')
        this.$root.Toast.fire({icon: "success",title:"Success, Thank you"})
        let link = await this.$store.dispatch("getRefLink",{email: this.emailBuyer})
        let MSG = (this.sacrifice == "I Want to Recieve My NFT") ? "I just purchased GOLDX Mining Rights you can too! " : "I just sacrificed GOLDX Mining Rights you can too!"
        if(link.status){
          const tweetContent = encodeURIComponent(`${MSG} \n #fusegold #GOLDX #GOLDXNFT #FORTG \n `);
          const mediaUrl = encodeURIComponent(link.refLink);
          const twitterUrl = `https://twitter.com/intent/tweet?text=${tweetContent}&url=${mediaUrl}`;
          window.open(twitterUrl,"_blank")
        }else{
          const tweetContent = encodeURIComponent(`${MSG} \n #fusegold #GOLDX #GOLDXNFT #FORTG \n `);
          const mediaUrl = encodeURIComponent("https://goldx.io/referral/0x560068787A16587415C0fd763c8a5df624B8A555");
          const twitterUrl = `https://twitter.com/intent/tweet?text=${tweetContent}&url=${mediaUrl}`;
          window.open(twitterUrl,"_blank")
        }
        }else{
        this.$root.Toast.fire({icon: "warning",title:"Failed to save tx data"})
        this.started = false

        }
        }else{
    this.$root.Toast.fire({icon: "warning",title:"Failed to complete transaction"})
    this.started = false


        }
      }else{
    this.$root.Toast.fire({icon: "warning",title:"Minimum Purchase Should be $62.5"})

      }
        
      },
      async fetchNFTs(){
        this.$store.dispatch("getNFTs")
      },
      async validateReferral(wallet){
        let wl = await this.$store.dispatch("getRef", {wallet})
        console.log(wl)
        this.refEmail = (wl.email) ? wl.email : '';
        this.refWallet = wl.wallet;
        this.refUsername = wl.username
  
      },
      async getDex() {
        let hbsc  = 'https://api.geckoterminal.com/api/v2/simple/networks/bsc/token_price/0x1a8abcfdf145379c2443eb7a6e3d127186c867db'
        let goldx =`${this.$store.state.URL}/api/goldx-price`
        axios.get(hbsc).then((res) => { 
          if(res.data.data){
            if(res.data.data.attributes){
              if(res.data.data.attributes.token_prices){
                if(res.data.data.attributes.token_prices['0x1a8abcfdf145379c2443eb7a6e3d127186c867db']){
                  this.currentPrice.HBSC = Number(res.data.data.attributes.token_prices['0x1a8abcfdf145379c2443eb7a6e3d127186c867db'])
                }
              }
            }
          }
        })
        axios.get(goldx).then((res) => { 
          // if(res.data){
            // if(res.data.pairs.length){
              // if(res.data.data.attributes.token_prices){
                // if(res.data.data.attributes.token_prices['0x4e0f32e8ee0e696a662e9575cffb1c4dc5a26a92']){
                  this.currentPrice.WGOLDX =  (res.data.price == null) ? 0 : Number(res.data.price).toFixed(5)
                  this.currentPrice.GOLDX =  (res.data.price == null) ? 0 : Number(res.data.price).toFixed(5)
                // }
              // }
            // }
          // }
        })
        

      },
      async getCg () {
        // let coins = await CoinGeckoClient.indexes.list();
        // console.log(coins)
        // let data = await CoinGeckoClient.coins.markets();
        let data = await CoinGeckoClient.coins.markets({
      vs_currency: 'usd', // Currency in which you want the prices (e.g., USD)
      ids: ['bitcoin', 'ethereum', 'tether', 'binancecoin', 'usd-coin', 'wrapped-bitcoin', 'dai', 'chainlink', 'tether-gold', 'pax-gold', 'wbnb'], // CoinGecko coin IDs
    });
        // console.log(data)
        data.data.forEach(element => {
          if (this.currs.includes(element.symbol)) {
            this.currentPrice[element.symbol.toUpperCase()] = element.current_price
          }
        });
        // console.log(this.currentPrice)
        return data;
      },
      // async getMt() {
      //   return await axios.get("https://www.metals-api.com/api/latest?access_key=2cgcx34npsxd81ik1n166r3bqzj97yo1ljf748z95y6zr3ha1tw13ttcne4x")
      //   .then((res) => { 
      //     console.log('getMt',res)
      //     return res
      //   })
      // }
    },
    data(){
      return {
        pk: 'pk_test_51KGEYvBG1iCuOIyq0FyyvsHew8NKWVGrQLUZ2NcicdJv27OoT5rr5zqSUgYXmcFBy4ZYxoBGxXSDk2ycx4AL5TNm00ylQQXQxm',
      elementsOptions: {
        clientSecret:null,
        appearance: {}, // appearance options
      },
      confirmParams: {
        return_url: this.$store.state.URL+'/api/success-payment/', // success url
      },
      pid:"",
        pType:"Crypto",
        refEmail:"",
        refWallet:"",
        showCharts:true,
        network:"https://goldxscan.com/tx/",
        // network:"https://bscscan.com/tx/",
        
        needSingle:"I Need Single NFT",
        sacrifice:"I Want to Recieve My NFT",
        // filteredUsers:[],
        isSearch:false,
        refUsername:"",
        currs:['btc','eth','usdt','bnb','usdc','wbtc','dai','link','xaut','paxg','wbnb'],
        currentPrice:{
        HBSC:0,
        WGOLDX:0,
        GOLDX:0,
        BNB: 245.26,
        BTC: 30519,
        DAI: 1,
        ETH: 1913.45,
        LINK: 6.44,
        USDC: 0.999942,
        USDX: 1,
        USDT: 1,
        WBTC: 30505
      },
        emailReferrer:'',
        emailBuyer:"",
        slQty:0,
        txHash:"",
        slClass:"Miners",
        headerBgVariant: 'light',
          headerTextVariant: 'dark',
          bodyBgVariant: 'light',
          bodyTextVariant: 'dark',
          footerBgVariant: 'light',
          footerTextVariant: 'dark',
        slCurr:"GOLDX",
        totalAmount:1250000,
        // refPrice:75000,
        // prosPrice:12.5,
        // minPrice:250,
        refPrice:300000,
        prosPrice:50,
        minPrice:1000,
        ETH_CHAIN_ID:"0x1",
        BSC_CHAIN_ID:"0x38",
        GOLDX_CHAIN_ID:"0xa573",
        // prosPrice:1,
        refiners:5,
        started:false,
        miners: 5625,
        isSelfMint:false,
        prospectors:112500,
        updating:false,
        search:"",
        currentPage:1,
        perPage:10,
        prfields:[
          {key: 'wallet',label: 'Address',sortable: false,"class":"col-uid"},
          {key: 'rank',label: 'Rank',sortable: false,"class":"col-rank",sortByFormatted: false},
  
          {key: 'nftsCounter',label: 'Total Ref.',sortable: true, sortByFormatted: false,"class":"col-total"
        },
        {key: 'nft',label: 'Purchased NFTs',sortable: true, sortByFormatted: false,"class":"col-total"
        },
          // {key: 'verif',label: 'Verified Ref.',sortable: true, sortByFormatted: true,"class":"col-verif"},
          // {key: 'unverif',label: 'Unverified Ref.',sortable: true, sortByFormatted: true,"class":"col-unverif"},
        // {key: 'esPoints',label: 'Points (Estimated)',sortable: true,"class":"col-epoints-le",
        //   sortByFormatted: false
        
        // },
        
          {key: 'totalRewards',label: 'VIP Points',sortable: true,"class":"col-esx-le",
          sortByFormatted: false,      
        },
        {key: 'usd',label: 'USD Rewards',sortable: true,"class":"col-esx-le",
          sortByFormatted: false,      
        },
        {key: 'badge',label: 'Leader Score',sortable: false,"class":"col-badge"},
  
        ],
  
      }
    },

    watch:{
      slClass(){
        if(this.slClass == "Refiners" ){
          console.log("val  == reiners")
          if(this.slCurr == "HBSC" || this.slCurr == "WGOLDX" || this.slCurr == "GOLDX" || this.slCurr == "USDX"){
            console.log("slCurr == goldx",this.slCurr)
            this.network = 'https://bscscan.com/tx/';
            this.slCurr = "BNB"
          }else{
            console.log("slCurr !== goldx",this.slCurr)
          }
        }
        this.slQty = 1

      },
      slCurr(){
        // this.slCurr == "WGOLDX"  && 
        if(this.slClass == "Refiners"){
          if(this.slCurr == "WGOLDX" || this.slCurr == "HBSC" || this.slCurr == "GOLDX" || this.slCurr == "USDX"){
            setTimeout(() => {
              this.network = 'https://bscscan.com/tx/';              
            this.slCurr = "BNB"
          }, 500);
          }
        }
        this.slQty = 1

      },
      // sacrifice(value){
      //   if(value == 'I Want to Recieve My NFT'){
      //     if(this.slCurr == "GOLDX" || this.slCurr == "WGOLDX" || this.network == 'https://goldxscan.com/tx/'){
      //       this.network = 'https://bscscan.com/tx/';
      //     }
      //   }
      // },
      // sacrifice == 'I Want to Sacrifice My NFT'
      pType(value){
        console.log(value)
        if(value == 'Fiat' && this.elementsOptions.clientSecret == null)
        {
          let amount = 0;
          if(this.slClass == "Prospectors" || this.slClass == "Custom") amount = ( 5 * this.slQty)
          if(this.slClass == "Miners") amount = ( 100 * this.slQty)
          if(this.slClass == "Refiners") amount = 25000
          console.log(amount)
          this.generatePaymentIntent(amount)
        }
      },
      // slClass(){
      // },
      network(value){
        // <option value="https://bscscan.com/tx/"> BSCscan </option>
        // <option value="https://etherscan.io/tx/"> Etherscan </option>
        if(value == "https://bscscan.com/tx/"){
          this.slCurr = "BNB" 
        }

        if(value == "https://goldxscan.com/tx/"){
          this.slCurr = "GOLDX" 
        }

        if(value == "https://etherscan.io/tx/")
        {
          this.slCurr = "ETH" 
        }
      }
    },
    computed:{
      bonus(){
        // if( this.slCurr == "HBSC" ){
          // return {bonus: 1}
        // }else{
        //   let startDate = "2024-04-25T00:00:00Z";
        // const currentDate = new Date();
        // const currentUTCDate = new Date(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate()));
        // const weekOne = new Date(startDate)
        // weekOne.setUTCDate(weekOne.getUTCDate() + 7);
        // const weekTwo = new Date(startDate)
        // weekTwo.setUTCDate(weekTwo.getUTCDate() + 14);
        // const weekThree = new Date(startDate)
        // weekThree.setUTCDate(weekThree.getUTCDate() + 21);
        // const weekFour = new Date(startDate)
        // weekFour.setUTCDate(weekFour.getUTCDate() + 28);
        // if (currentUTCDate < weekOne) {
        //   return {startDate, currentUTCDate, weekOne, weekTwo, weekThree, weekFour, bonus:2.5};
        // } else if (currentUTCDate < weekTwo) {
        //   return {startDate, currentUTCDate, weekOne, weekTwo, weekThree, weekFour, bonus:2};
        // } else if (currentUTCDate < weekThree) {
        //   return {startDate, currentUTCDate, weekOne, weekTwo, weekThree, weekFour, bonus:1.25};
        // } else if (currentUTCDate < weekFour) {
        //   return {startDate, currentUTCDate, weekOne, weekTwo, weekThree, weekFour, bonus:1};
        // } else {
        //   return {startDate, currentUTCDate, weekOne, weekTwo, weekThree, weekFour, bonus:1};
        // }
          // return { bonus:25};
          if(this.slCurr == "DAI" || this.slCurr == "USDT" || this.slCurr == "USDC" || this.slCurr == "ETH" || this.slCurr == "BNB" ){
            // return 0.25
          return { bonus:25};

          }else{
            return { bonus:1};
            // return 1
          }
        // }
        
      },
      tPoints(){
        let price = 0
        if(this.slClass == "Miners") price = this.minPrice
        if(this.slClass == "Prospectors" || this.slClass == "Custom") price = this.prosPrice
        if(this.slClass == "Refiners") price = this.refPrice
        let total = price * this.slQty;
        total = (total * this.diss)
        let points = (total * 1000) * this.bonus.bonus;
        return points; 
      },
      totalSales(){
        return Number(this.$store.state.totalSales.perc)
      },
      diss(){
        if(this.slClass == "Refiners"){
          return 1
        }else{
          if(this.totalSales < 2.5){
        // if((this.power) < (14.6) + 2.5){
          if(this.slCurr == "USDX" || this.slCurr == "WBNB" || this.slCurr == "WBTC" || this.slCurr == "PAXG" || this.slCurr == "XAUT" || this.slCurr == "HBSC" || this.slCurr == "DAI" || this.slCurr == "USDT" || this.slCurr == "USDC" || this.slCurr == "ETH" || this.slCurr == "BNB" ){
            return 0.10
          }else{
            return 1
          }
        }else{
          return 1
        }
        }
        
        
//  let price = 5;
//        if(this.slClass == "Refiners") price = 25000 
//        if(this.slClass == "Miners") price = 100 
//        if(this.slClass == "Prospectors") price = 5 
//         let total = price * this.slQty;
//         if(total > 99.9){
//           return 0.5
//         }else{
//           return 1
//         }
        // }else{
          // return 1
        // }
       
      },
      VerEmailBuyer()
      {
        // const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

      if(this.emailBuyer.length)
      {
        if(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.emailBuyer)){
return 'border border-success'
        }else{
return 'border border-danger'
        }

      }else{
        return "border border-warning"
      }
      },
      users(){
        let us = this.$store.state.users
        if(us){
          us.sort(function(b, a) {
    return a.rewards - b.rewards;
  });
  return us;
        }else{
          return []
        }
        
      },
      filteredUsers(){
        if(this.isSearch){
         let users = [];
        //  this.isSearch = true
         this.users.forEach(element => {
          if (element.wallet == this.search) {
            users.push(element)
          }
         });
     
         return users
        }else{
        // this.isSearch = false
        
          return this.users
        }
      },
      filteredUsersCalcs(){
        let users = this.filteredUsers;
        this.filteredUsers.forEach((element, index) => {
          // users[index].rewards = 0
          users[index].totalRewards = (element.rewards + element.buyRewards) 
        });
        return users;
      },
      minersSold(){
        return this.$store.state.miners
      },
        refinersSold(){
        return this.$store.state.refiners
      },
        prospectorsSold(){
        return this.$store.state.prospectors
      },
      maxQty(){
        let qty = 0
        if(this.slClass == "Refiners") qty = (this.refiners - this.refinersSold)
        if(this.slClass == "Miners") qty =  (this.miners - this.minersSold)
        if(this.slClass == "Prospectors") qty =  (this.prospectors - this.prospectorsSold)
        if(this.slClass == "Custom") qty =  100000
  
        return qty
      },
      totalNFTs(){
        return (this.refiners + this.miners + this.prospectors)
      },
      amountRaised() {
        let ref = this.refPrice * this.refinersSold;
        let min = this.minPrice * this.minersSold;
        let pros = this.prosPrice * this.prospectorsSold;
        return (Number(ref) + Number(min) + Number(pros))
      },
      nftSold() {
        return (this.refinersSold + this.minersSold + this.prospectorsSold)
      },
      
    },
  }
  </script>
  <style>
  .link{
    cursor:pointer;
    text-decoration:underline;
  }
  .hs h5{
    font-size: 115%;
  }
  .hsm h5{
    font-size: 90%;
  }
  #dropdown-1__BV_toggle_{width: 100%;font-size: 13px; padding: 8px;}
  .ft5{
    font-size: 13px;
  }
  .b-text{
    font-weight: 600;
  }
  
  @keyframes glow {
  0% { text-shadow: 0 0 5px #b99653; }
  50% { text-shadow: 0 0 20px #b99653; }
  100% { text-shadow: 0 0 5px #b99653; }
}

/* Apply the glow animation to the text */
.glowing-text {
  animation: glow 1.5s infinite alternate; /* Alternate the animation direction */
  color: #b99653; /* Gold color */
}
.spinner-border{
  display: inline-block;
    vertical-align: -.125em;
    border: .25em solid #FFF !important;
    border-right-color: #b99653 !important;
    border-radius: 50%;
    animation: .75s linear infinite spinner-border;
}
  </style>